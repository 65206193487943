
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { PropsWithChildren } from "react";
import { _ } from "@sablier/v2-mixins";
import { ThemeProvider, getTheme } from "@sablier/v2-themes";
import { vendors } from "@sablier/v2-utils";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import "cross-fetch";
import Progress from "next-progress";
import { ReactQueryProvider, WatcherProvider, Web3Provider, } from "~/client/contexts";
import { vendors as overrides } from "~/client/utils";
import type { AppPropsHydrated, PagePropsHydrated } from "~/client/types";
vendors.track.init();
const theme = getTheme();
function Wrapper({ children, pageProps, }: PropsWithChildren<PagePropsHydrated>) {
    return (<ThemeProvider>
      <ReactQueryProvider pageProps={pageProps}>
        <Web3Provider>
          <WatcherProvider>{children}</WatcherProvider>
        </Web3Provider>
      </ReactQueryProvider>
    </ThemeProvider>);
}
function App({ Component, pageProps }: AppPropsHydrated) {
    return (<>
      <Wrapper pageProps={pageProps}>
        <Progress color={theme.colors.white} delay={500} height={"2px"} options={{ showSpinner: false }}/>
        <Component {...pageProps}/>
        <Analytics beforeSend={overrides.vercel.beforeSendAnalytics} debug={false}/>
      </Wrapper>
      <SpeedInsights beforeSend={overrides.vercel.beforeSendSpeedInsights} debug={false}/>
    </>);
}
const __Next_Translate__Page__19195526e74__ = App;

    export default __appWithI18n(__Next_Translate__Page__19195526e74__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  